import './App.css';
import Main from './component/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return (
   <div>
    <Main /> 
   </div>
  );
}

export default App;
